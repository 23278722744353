// Google Analytics
const host = window.location.hostname;
if (host !== 'localhost') {
  window.dataLayer = window.dataLayer || []
  function gtag() { dataLayer.push(arguments) }
  gtag('js', new Date())
  const trackGoogleAnalytics = (event) => {
    gtag('config', 'G-S1S3Q8QLDK', {
      'cookie_flags': 'max-age=7200;secure;samesite=none'
    })
  }
  document.addEventListener('turbo:load', trackGoogleAnalytics)
}
